require('normalize.css/normalize.css');
require('foundation-sites/dist/css/foundation.min.css');
require('foundation-sites');
require('./main.css');
require('./fontawesome.min.css');
require('./fonts/QuattrocentoSans-Regular.ttf');
require('./sitemap.xml');

var $ = require('jquery');
var lazyLoad = require('vanilla-lazyload');

// Load Images
var logoImage = require('./images/logo.png');
var jumbotronImage = require('./images/jumbotron.jpeg');
var service1Image = require('./images/spraying_1.jpg');
var service2Image = require('./images/spraying_2.jpg');
var service3Image = require('./images/spraying_3.jpg');
var service4Image = require('./images/spraying_4.jpg');
var replace1Image = require('./images/replace_1.jpg');
var replace2Image = require('./images/replace_2.jpg');
var replace3Image = require('./images/replace_3.jpg');
var colourPickerImage = require('./images/colourpicker-default.jpg');
var colour1 = require('./images/colour1.jpg');
var colour2 = require('./images/colour2.jpg');
var colour3 = require('./images/colour3.jpg');
var colour4 = require('./images/colour4.jpg');
var colour5 = require('./images/colour5.jpg');
var colour6 = require('./images/colour6.jpg');
var colour7 = require('./images/colour7.jpg');
var colour8 = require('./images/colour8.jpg');
var recent1Finished = require('./images/recent_1_finished.jpg');
var recent1Prep = require('./images/recent_1_prep.jpg');
var recent2Finished = require('./images/recent_2_finished.jpg');
var recent2Prep = require('./images/recent_2_prep.jpg');
var recent3Finished = require('./images/recent_3_finished.jpg');
var recent3Prep = require('./images/recent_3_prep.jpg');
var recent4Finished = require('./images/recent_4_finished.jpg');
var recent4Prep = require('./images/recent_4_prep.jpg');
var recent5Finished = require('./images/recent_5_finished.jpg');
var recent5Prep = require('./images/recent_5_prep.jpg');
var recent6Finished = require('./images/recent_6_finished.jpg');
var recent6Prep = require('./images/recent_6_prep.jpg');
var recent7Finished = require('./images/recent_7_finished.jpg');
var recent7Prep = require('./images/recent_7_prep.jpg');
var recent8Finished = require('./images/recent_8_finished.jpg');
var recent8Prep = require('./images/recent_8_prep.jpg');
var recent9Finished = require('./images/recent_9_finished.jpg');
var recent9Prep = require('./images/recent_9_prep.jpg');

var years20 = require('./images/20Years.png');
var guarantee10 = require('./images/10years.png');
var RAL = require('./images/RAL.png');

$('.logo').attr('data-src', logoImage); // Logo Image
$('#jumbotron-image').attr('data-src', jumbotronImage); // Jumbotron Image
$('#servicesOne').attr('data-src', service1Image); // Service 1 Image
$('#servicesTwo').attr('data-src', service2Image); // Service 2 Image
$('#servicesThree').attr('data-src', service3Image); // Service 3 Image
$('#servicesFour').attr('data-src', service4Image); // Service 3 Image
$('#replaceOne').attr('data-src', replace1Image); // Replace 1 Image
$('.replaceTwo').attr('data-src', replace2Image); // Replace 2 Image
$('#replaceThree').attr('data-src', replace3Image); // Replace 3 Image
$('#colourPickerImage').attr('data-src', colourPickerImage); // Colour Picker Image
$('.colour1').attr('data-src', colour1); // Colour Picker 1
$('.colour2').attr('data-src', colour5); // Colour Picker 2
$('.colour3').attr('data-src', colour6); // Colour Picker 3
$('.colour4').attr('data-src', colour7); // Colour Picker 4
$('.colour5').attr('data-src', colour2); // Colour Picker 5
$('.colour6').attr('data-src', colour3); // Colour Picker 6
$('.colour7').attr('data-src', colour4); // Colour Picker 7
$('.colour8').attr('data-src', colour8); // Colour Picker 8
$('#recent1-finished').attr('data-src', recent1Finished); // Recent 1 Finished
$('#recent1-prep').attr('data-src', recent1Prep); // Recent 1 Finished
$('#recent2-finished').attr('data-src', recent2Finished); // Recent 2 Finished
$('#recent2-prep').attr('data-src', recent2Prep); // Recent 2 Finished
$('#recent3-finished').attr('data-src', recent3Finished); // Recent 3 Finished
$('#recent3-prep').attr('data-src', recent3Prep); // Recent 3 Finished
$('#recent4-finished').attr('data-src', recent4Finished); // Recent 4 Finished
$('#recent4-prep').attr('data-src', recent4Prep); // Recent 4 Finished
$('#recent5-finished').attr('data-src', recent8Finished); // Recent 5 Finished
$('#recent5-prep').attr('data-src', recent8Prep); // Recent 5 Finished
$('#recent6-finished').attr('data-src', recent6Finished); // Recent 6 Finished
$('#recent6-prep').attr('data-src', recent6Prep); // Recent 6 Finished
$('#recent7-finished').attr('data-src', recent7Finished); // Recent 7 Finished
$('#recent7-prep').attr('data-src', recent7Prep); // Recent 7 Finished
$('#recent8-finished').attr('data-src', recent5Finished); // Recent 8 Finished
$('#recent8-prep').attr('data-src', recent5Prep); // Recent 8 Finished
$('#recent9-finished').attr('data-src', recent9Finished); // Recent 9 Finished
$('#recent9-prep').attr('data-src', recent9Prep); // Recent 9 Finished

$('#years20').attr('data-src', years20); // 20 Years Experience
$('#guarantee10').attr('data-src', guarantee10); // 10 Year Guarantee
$('#RAL').attr('data-src', RAL); // RAL Logo

// Set up the Email Modal
let emailModal = $('#email-modal');
var elem = new Foundation.Reveal(emailModal);

// Set up the Privacy Modal
let privacyModal = $('#privacy-modal');
var elem = new Foundation.Reveal(privacyModal);


$(document).ready(() => {
    new lazyLoad({
        elements_selector: ".lazy"
    });

    // Show the facebook popup when clicked.
    $('.fa-facebook-messenger').on('click', () => {
        FB.CustomerChat.showDialog();
    });

    // Handle colour pickings
    $('.colour1').on('click', () => {
        var colourPicked = require('./images/colourpicker-7016.jpg');
        $('#colourPickerImage').attr('src', colourPicked);
        $('.selected-border').removeClass("selected-border");
        $('.colour1').addClass("selected-border");
    });

    $('.colour2').on('click', () => {
        var colourPicked = require('./images/colourpicker-7015.jpg');
        $('#colourPickerImage').attr('src', colourPicked);
        $('.selected-border').removeClass("selected-border");
        $('.colour2').addClass("selected-border");
    });

    $('.colour3').on('click', () => {
        var colourPicked = require('./images/colourpicker-7005.jpg');
        $('#colourPickerImage').attr('src', colourPicked);
        $('.selected-border').removeClass("selected-border");
        $('.colour3').addClass("selected-border");
    });

    $('.colour4').on('click', () => {
        var colourPicked = require('./images/colourpicker-7042.jpg');
        $('#colourPickerImage').attr('src', colourPicked);
        $('.selected-border').removeClass("selected-border");
        $('.colour4').addClass("selected-border");
    });

    $('.colour5').on('click', () => {
        var colourPicked = require('./images/colourpicker-9005.jpg');
        $('#colourPickerImage').attr('src', colourPicked);
        $('.selected-border').removeClass("selected-border");
        $('.colour5').addClass("selected-border");
    });

    $('.colour6').on('click', () => {
        var colourPicked = require('./images/colourpicker-bs14c35.jpg');
        $('#colourPickerImage').attr('src', colourPicked);
        $('.selected-border').removeClass("selected-border");
        $('.colour6').addClass("selected-border");
    });

    $('.colour7').on('click', () => {
        var colourPicked = require('./images/colourpicker-9001.jpg');
        $('#colourPickerImage').attr('src', colourPicked);
        $('.selected-border').removeClass("selected-border");
        $('.colour7').addClass("selected-border");
    });

    $('.colour8').on('click', () => {
        var colourPicked = require('./images/colourpicker-bs18c33.jpg');
        $('#colourPickerImage').attr('src', colourPicked);
        $('.selected-border').removeClass("selected-border");
        $('#colour8').addClass("selected-border");
    });

    $('#colourReset').on('click', () => {
        $('#colourPickerImage').attr('src', colourPickerImage);
        $('.selected-border').removeClass("selected-border");
    });
});



$(document).ready(() => {

    var sliderTime = 4000;
    var transitionTime = 1500;

    $(".slideshow1 > div:gt(0)").hide();
    setInterval(function () {
        $('.slideshow1 > div:first')
            .fadeOut(transitionTime)
            .next()
            .fadeIn(transitionTime)
            .end()
            .appendTo('.slideshow1');
    }, sliderTime);

    $(".slideshow2 > div:gt(0)").hide();
    setInterval(function () {
        $('.slideshow2 > div:first')
            .fadeOut(transitionTime)
            .next()
            .fadeIn(transitionTime)
            .end()
            .appendTo('.slideshow2');
    }, sliderTime);

    $(".slideshow3 > div:gt(0)").hide();
    setInterval(function () {
        $('.slideshow3 > div:first')
            .fadeOut(transitionTime)
            .next()
            .fadeIn(transitionTime)
            .end()
            .appendTo('.slideshow3');
    }, sliderTime);

    $(".slideshow4 > div:gt(0)").hide();
    setInterval(function () {
        $('.slideshow4 > div:first')
            .fadeOut(transitionTime)
            .next()
            .fadeIn(transitionTime)
            .end()
            .appendTo('.slideshow4');
    }, sliderTime);

    $(".slideshow5 > div:gt(0)").hide();
    setInterval(function () {
        $('.slideshow5 > div:first')
            .fadeOut(transitionTime)
            .next()
            .fadeIn(transitionTime)
            .end()
            .appendTo('.slideshow5');
    }, sliderTime);

    $(".slideshow6 > div:gt(0)").hide();
    setInterval(function () {
        $('.slideshow6 > div:first')
            .fadeOut(transitionTime)
            .next()
            .fadeIn(transitionTime)
            .end()
            .appendTo('.slideshow6');
    }, sliderTime);

    $(".slideshow7 > div:gt(0)").hide();
    setInterval(function () {
        $('.slideshow7 > div:first')
            .fadeOut(transitionTime)
            .next()
            .fadeIn(transitionTime)
            .end()
            .appendTo('.slideshow7');
    }, sliderTime);

    $(".slideshow8 > div:gt(0)").hide();
    setInterval(function () {
        $('.slideshow8 > div:first')
            .fadeOut(transitionTime)
            .next()
            .fadeIn(transitionTime)
            .end()
            .appendTo('.slideshow8');
    }, sliderTime);

    $(".slideshow9 > div:gt(0)").hide();
    setInterval(function () {
        $('.slideshow9 > div:first')
            .fadeOut(transitionTime)
            .next()
            .fadeIn(transitionTime)
            .end()
            .appendTo('.slideshow9');
    }, sliderTime);

    $("#contact-form").submit(e => {
        e.preventDefault();
        send_email();
        return false;
    });

    function send_email() {
        $('.sending').show();
        $('.submit-button').hide();

        $.ajaxPrefilter(function (options, originalOptions, xhr) {
            if (options.crossDomain) {
                try {
                    delete options.headers["X-CSRF-Token"]
                } catch (err) { }
            }
        });

        var data = $("#contact-form")
            .serializeArray()
            .reduce(function (obj, item) {
                obj[item.name] = item.value;
                return obj;
            }, {});

        $.ajax({
            url: "https://us-central1-proview-coatings.cloudfunctions.net/email",
            type: 'GET',
            dataType: 'json',
            crossDomain: true,
            contentType: 'application/json',
            data: data,
            error: function (e) {
                console.log(e);
                alert("There was a problem sending the email, please try again, or another contact option.");
            },
            success: function (e) {
                $('.sending').hide();
                $('.submit-button').show();
                alert("Your email has been sent! You shall receive a response soon.");
                location.reload();
            }
        });
    }

});
